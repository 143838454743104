import $ from "cash-dom";
import gsap from "gsap";
import _ from "lodash";
import enquire from 'enquire.js';
import 'alpinejs';


var fk = {
	accordions: [],
	menuTrigger: document.getElementById('menu-trigger'),
	menu: document.getElementById('menu'), 
	nav: document.getElementById('nav'),
	picpile: document.getElementById('picpile'),
	debounce: function(func) {
		var wait = arguments.length <= 1 || arguments[1] === undefined ? 100 : arguments[1];
	  
		var timeout = void 0;
		return function () {
		  var _this = this;
	  
		  for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		  }
	  
		  clearTimeout(timeout);
		  timeout = setTimeout(function () {
			func.apply(_this, args);
		  }, wait);
		};
	}
};

fk.menuTrigger.onclick = function() {
	fk.menu.classList.toggle('active');
}

menu.addEventListener('click', function (event) {
	// If the clicked element doesn't have the right selector, bail
	if (!event.target.matches('.menucloser')) return;

	// Don't follow the link
	// event.preventDefault();
	fk.menu.classList.toggle('active');
	// Log the clicked element in the console

}, false);

// TODO change na behaviour smooth
// var scroll = new SmoothScroll('a[href*="#"]', {
// 	speed: 400,
// 	easing: 'easeOutCubic',
// 	offset: function (anchor, toggle) {
// 		if (toggle.classList.contains('footnav')) {
// 			return 110;
// 		} else {
// 			return 0;
// 		}
// 	}
// });

// document.querySelectorAll('.handorgel').forEach(function(el) {
// 	fk.accordions.push(new handorgel(el, {}));
// });




fk.topDealer = function() {
	console.log('topdealer');
	  // detects new state and compares it with the new one
	if ((document.body.getBoundingClientRect()).top < -10) {
		fk.picpile.className = "";
	} else {
		fk.picpile.className = "on";
	}
	// saves the new position for iteration.
	// fk.scrollPos = (document.body.getBoundingClientRect()).top;
}

if(document.body.classList.contains('home')) {
	fk.throttledDealer = _.throttle(fk.topDealer, 50);
	window.addEventListener('scroll', fk.throttledDealer);
 

}



function makeColumnColapser() {

	$('#bricks').on('click', '.brick', function() {
		
		let $brick = $(this);
		let $content = $brick.find('.content');
		if(!$brick.hasClass('closed')) {
			gsap.to($content, 0.4, {height:0})
			$brick.addClass('closed');
		} else {
			gsap.set($content, {height: "auto"});
			gsap.from($content, 0.4, {height: 0});
			$brick.removeClass('closed');
		}

	});




}






function makeCols() {
	let $bricks = $('#bricks');
	let $even = $('.brick:nth-child(even)');
	let $odd = $('.brick:nth-child(odd)');

	$bricks.children().detach();
	$bricks.append('<div class="leftbricks w-1/2 pr-4"></div><div class="rightbricks w-1/2 pl-4"></div>');
	
	$even.appendTo('.rightbricks');
	$odd.appendTo('.leftbricks');

}




makeColumnColapser();


enquire.register("screen and (min-width:1000px)", {

    match : function() {
		console.log('match screen and (min-width:1000px)');
		makeCols();

	
	},    

    unmatch : function() {

		console.log('unmatch screen and (min-width:1000px)');
	},  

    setup : function() {


		console.log('setup screen and (min-width:1000px)');
	}, 
    destroy : function() {},  
});


enquire.register("screen and (max-width:1000px)", {

    match : function() {
	
		console.log('match screen and (max-width:1000px)');
	},    

    unmatch : function() {

		console.log('unmatch screen and (max-width:1000px)');
	},  

    setup : function() { 
		

		console.log('setup screen and (max-width:1000px)');
	}, 
    destroy : function() {},  
});